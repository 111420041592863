import "./layout.css"


function Layout(props) {
    return (
      <>
        <div>{props.header}</div>
        <div class="container">
          {props.content}
        </div>

      </>
    );
  }

  export default Layout