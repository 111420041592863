import "./backgroundWave.css"
import Header from '../header/header';
function BackgroundWave(props)
{
return(
    
    <div class="background-wave col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
  <div class="container-flex svg">      {props.header} </div>
    </div>
    
)
}

export default BackgroundWave

