import movie1 from '../../assets/videos/nest.mp4'
import Topicality from '../../components/topicality/topicality'
import img1 from '../../assets/img/305831613_177920724807039_907315246950720377_n.jpeg'


const backendNews = [
  {
        id: 8,
        title: "News",
        person: "Marek Łepkowski",
        date: "08.09.2022",
        description: `NEST an European strategical security R&D project to protect against CBRN attacks,
        lead by the Spanish company AMPER S&C IOT S.L.
        
        CBRN stands for Chemical, Biological, Radiological and Nuclear. Often this term is used to describe
        incidents that involve one of these materials, ranging from an industrial accident to a terrorist attack.
        Recently, CBRN as acquired more relevance because of the attacks of Russia to Ukraine. The main
        objective is to ensure responders have robust planning and response strategies to reduce the likelihood and
        impact of these kinds of incidents.
        NEST will design and implement a novel and unique standoff system with the capability to detect multiple
        threats amongst which CBRN threats or pandemic viruses. It will support owners, operators, and security
        staff by providing (i) threat indications and warnings, and (ii) guidance for facility security by developing
        appropriate information-sharing and analysis mechanisms. The system will rely on the simultaneous use of
        low-cost CBRN detectors embedded in one unique detection equipment, which can be located into different
        sites inside the building or carried by security staff. The use of low-cost sensors will enable to cover a wide
        space inside. NEST will help in the early detection of CBRN threats in real time, and also provide
        complementary information—such as such location of threats, temperature, humidity, time, operators
        involved, etc.—useful for auditing or investigation purposes. These functionalities will be achieved by
        using an IoT platform capable of acquiring, processing, and merging data from internal and third-party
        services. Artificial intelligence will be applied to support decision process for securing facilities and for
        generating automatic alerts. Furthermore, augmented reality will be used to display threats and hazards in
        a manner that minimise distraction and cognitive failures.
        The project with duration of 36 months (May 2021 - May 2024) and a total budget of 3.5 Million Euros
        brings together a well-balanced European consortium in terms of involvement of industrial and SME
        partners: WoePal GmbH (Germany), Thales (Portugal), Dynamic Safety Corporation (Poland); Academic
        and research institutions: Consejo Superior de Investigaciones Científicas (Spain), University of Lodz
        (Poland), Asociación Española de Normalización (Spain); end-users partners from commercial and
        transportation facilities: Comboios de Portugal (Portugal), Lech Poznań (Poland), and Atiram Hotels
        (Spain). Furthermore, NEST has initiated a Security Advisory Board which consists of Mossos d'Esquadra
        as Law Enforcement Agency (LEA), and experts in the CBRN field. In addition, an Ethics Committee will
        assess ethical aspects of the project.`,
        img: [],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02V75dhsHBQnrTDEp3RzdPZgichFBDDnc6CP8WDEbKE3Sgi65TJ5xfrLPVw8w4SNiRl"
    },
    {
        id: 5,
        title: "News",
        person: "Marek Łepkowski",
        date: "08.09.2022",
        description: "AMPER Sensing and Control Systems S.L IOT. (S&C) from Spain, NEST Partner project, participated in Mobile World Congress 2022 in Barcelona on 28/02/22 - 03/03/22. In conference participated 1500 exhibitors and attendees from 165 countries. <br/><a href=`https://www.mwcbarcelona.com/` target=`_blank`> https://www.mwcbarcelona.com/ </a>",
        img: [],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02V75dhsHBQnrTDEp3RzdPZgichFBDDnc6CP8WDEbKE3Sgi65TJ5xfrLPVw8w4SNiRl"
    },
    {
        id: 4,
        title: "News",
        person: "Marek Łepkowski",
        date: "08.06.2022",
        description: `Leading Exhibition for Logistics, Transport, Intralogistics and Supply Chain in Southern Europe - 22nd edition of SIL BARCELONA will take place from May 31 to June 2, 2022.
        In the Congress and Exhibition will attend 650
        exhibitors, 300 speakers from 75 countries.
        SIL, as an international business bridge between
        Europe, Latin America and the Mediterranean, will gather the main
        professionals, companies and entities that are encompassed in the supply chain.
        AMPER Sensing and Control Systems S.L IOT. (S&C) will represent NEST project (a poster and a video will be shown in the S&C stand F657).<br> <a href="https://www.silbcn.com/en/index.html">https://www.silbcn.com/en/index.html </a>`,
        img: [], 
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid037kduEPgkGYiUL3UXahEoJVmggfHvntwsVBF23hXEvy2c5JBGswDKYVpVRWvuMB3Hl"
    },
    {
        id: 3,
        title: "News",
        person: "Marek Łepkowski",
        date: "14.08.2022",
        description: `<p>Busy days in Lisbon behind us!
        <br><br>    
        NEST consortium plenary meeting was held at CP on 5th and 6th of July .
        <br><br> 
        The two-day event kicked off with an overview of the project status, putting special emphasis on the work to be done in the following months and the most urgent deliverables.
        <br><br> 
        The fruitful discussion was followed by a demonstration of the possibile augmented reality equipment which can be created.
        <br><br> 
        The second day continued at the CP pilot site. Participants had an opportunity to visit the train station as well as CP infrastructure with special focus on trains, which will ultimately have the sensors installed. A technician supported the consortium in choosing the sensor location as well as the appropriate communication devices. One of the main challenges, the bottleneck, will be the location of biological sensors due to their large size.
        <br><br> 
        The agenda goals were met. The activities and main course of actions were presented and the participants discussed what will be rolled out in the near future. A series of open discussions, thoughtfull advice and comments on many topics allowed for deep understanding of the matter for all sides taking part in the meeting.</p>`,
        img: [{link: require("../../assets/img/1.jpeg")}, {link: require("../../assets/img/2.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02bQiVLQ8dbB77AtbTxMATbqorebuuBFrWQZdtmbzwSU4th8EtXjUwj4Go6Dz2muHel"
    },
    {
        id: 1,
        title: "News",
        person: "Marek Łepkowski",
        date: "08.08.2022",
        description: "DG HOME and REA C2 unit are jointly organising from 30 June to 1 July the third edition of the Project to Policy Kick-Off Seminar (P2PKOS), now renamed as Projects to Policy Seminar (PPS). <br/><a href=https://nest-h2020.eu/DOC/PressRelase_2.pdf > PressRelase_2.pdf </a>  <br/><a href=https://nest-h2020.eu/DOC/NEST_Presentation_01.07.2022_Brussels_final_end.pdf > NEST_Presentation_01.07.2022.pdf`</a>",
        img: [{link: require("../../assets/img/305831613_177920724807039_907315246950720377_n.jpeg")},{link: require("../../assets/img/305420304_177920768140368_723190045135335145_n.jpeg")}, {link: require("../../assets/img/305844981_177920804807031_3413824123429607499_n.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02L56BaERRFa4uyTCnY5yvCaQHabLeyLh15De3m887T7AaRf7c3tkrMyLSQ6h5xeH2l"
    
    },
    {
        id: 2,
        title: "News",
        person: "Marek Łepkowski",
        date: "08.09.2022",
        description: `DSC
        We attended jubilee 30th International Defence Industry Exhibition in Kielce – MSPO 2022.
        MSPO is ranked third among all Europe's exhibition, after the Paris and London trade shows.
        Taking into account our NEST project it was it a great opportunity to interact with companies from the CBRN industry and for dissemination, communication and exploitation activities.`,
        img: [{link: require("../../assets/img/305802271_177938961471882_5816993171171283849_n.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02V75dhsHBQnrTDEp3RzdPZgichFBDDnc6CP8WDEbKE3Sgi65TJ5xfrLPVw8w4SNiRl"
    },

    {
        id: 7,
        title: "News",
        person: "Marek Łepkowski",
        date: "09.11.2022",
        description: `DSC
        On October 25, 2022 at the PGE National Stadium was a presentation of equipment from a scientific
        research project co-financed by the Polish National Center for Research and Development(NCBR)
        entitled &quot;Mobile kit for analysis and elimination of contamination, supporting counter-terrorist
        activities at the site of the CBRN-E incident.&quot; The show was to present the operation of the Police
        sub-unit using the developed solutions in conditions similar to real ones.
        The show was attended by representatives of the Polish Police, the State Fire Service, NCBR, the
        Ministry of National Defense(MON), the State Protection Service(SOP), and the authorities of the city
        of Warsaw.
        After the exercises, the participants could see the equipment and talk about CBRN-related topics.
        During backstage meetings, materials related to the EU projects like NEST and Safe Stadium were
        presented.`,
        img: [{link: require("../../assets/img/091120221.jpg")},{link: require("../../assets/img/091120222.jpg")}, {link: require("../../assets/img/091120223.jpg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid0agVDuEmAkTSVaCyVHQp1B5hzx71sXW66W17AtVj1CT1a1VWXaPcCw9AXvmpvtcEgl"
    },

    {
        id: 10,
        title: "News - 74 th  COLPOFER General Assembly meeting – NEST participation",
        person: "Marek Łepkowski",
        date: "09.11.2022",
        description: `On the 14th and 15th of December 2022, Comboios de Portugal (CP), partner of the NEST project, hosted the 74th COLPOFER General Assembly. <br><br> COLPOFER was created in 1980 by a group of railway companies and railway police forces. With increasing awareness concerning security issues within the UIC, the organization subsequently became a Special Group of the UIC. <br><br> COLPOFER includes representatives of railway companies and of police authorities in charge of the surveillance of the railway environment, currently includes 32 member organizations from 25 European countries.<br><br> The afternoon of the 14th of December was dedicated to various presentations related to security in Rail done by entities external to the association. The following communications were done:
        <br><br>
        • Training of the law enforcement authorities
        – representative of PSP – CEPOL European
        Union Agency for Law Enforcement Training;
        <br><br>
        • NEST project, An interoperable multidomain
        CBRN system – representatives of
        Thales/GTSPT;
        <br><br>
        • The Portuguese Security System and the Special Unit of the national Police –
        representative of Special Unit Portuguese
        Police;
        <br><br>
        • Cyber Security, Cyber defence –
        representative/specialist from Cyber
        Defence;
        <br><br>
        • Major events and Critical Infrastructure –
        representative of GNR
        • Civil protection emergencies and Natural
        threats – representative of ANPC Portuguese
        Civil Protection.
        <br><br>
        Most of the COLPOFER organizations were
        represented in the meeting with around 20
        participants.
        <br><br>
        TH NEST presentation was considered
        interesting with an animated Q&amp;A period.
        Dealing with false positives was the main topic
        of discussion.
        `,
        img: [{link: require("../../assets/img/20221214_145522.jpg")},{link: require("../../assets/img/20221214_145530.jpg")}, {link: require("../../assets/img/20221214_145809.jpg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid0z5tEabpkJvGcnJ6Jq39BD9BQgY2ofSHbwnQYHPBBDBcHXyJq5jG4BuEykWDeHjV6l"
    },
    {
        id: 11,
        title: "News",
        person: "Marek Łepkowski",
        date: "08.09.2022",
        description: `A large-scale event at the PGE
        Narodowy Stadium in Warsaw
        On 17 May, we are planning a large-scale event at the PGE Narodowy Stadium in
        Warsaw. The event will be a Joint demonstration of ISFP SAFE-STADIUM, H2020
        HoloZcan &amp; H2020 NEST projects. We have planned a test of SAFE-STADIUM
        procedures and their synergies with H2020 projects – NEST and HoloZcan.
        The HoloZcan project is developing a novel AI-supported technology solution for the
        detection of biological threats, while the NEST project is using multiple sensor
        systems to detect CBRN agents. The SAFE-STADIUM project focuses on the CBRN
        protection procedures of sport facilities.
        Attendees will have the opportunity to see firsthand the latest advancements in
        technology and solutions for protecting mass events in the context of terrorist attacks
        using CBRN agents.`,
        img: [{link: require("../../assets/img/stadion.jpg")},],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02usxwfh4RSonZHp21CokndnKVn6MQhiojdZcjUi4rb9sCytewEZhF2frdJDH5pukhl"


    },  
    {
        id: 12,
        title: "News - The CSTAC meeting as a group of CBRN and Standardisation Cluster (CSTAC).",
        person: "Marek Łepkowski",
        date: "01.06.2023",
        description: `The CSTAC meeting as a group of CBRN and Standardisation Cluster (CSTAC).

        NEST participation as a project in cluster.
        
        The CSTAC meeting as a group of CBRN and Standardisation Cluster (CSTAC). This project is facilitated by the iniciative CMINE (Crisis Management
        
        Innovation Network Europe) and is funded by the European Commission through its Horizon program. The community is currently facilitated and maintained by the Resilience Advisors Network under a formal agreement created with the European Research Executive Agency.
        
        You can find more information in this web page https://www.cmine.eu/ #CSTAC is the new cluster of #HorizonEU and #H2020 projects launched by @peers_eu to foster #synergies on #cbrn and #standardisation. Under the leadership of @CMINE_EU, 11 projects have so far joined it. Participation is open to other projects willing to join forces! <a href="https://nest-h2020.eu/DOC/Press_report_7_NEWS.docx">Press_report_7_NEWS.docx</a>`,
        img: [{link: require("../../assets/img/image1062023.png")},],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid0271KcdawcG2tzdr4m5S8ocvnc9KVQDFncz48CG5st5Gz25X8gFwMC8ndPchCmdWxWl"
    },

    {
        id: 13,
        title: "News - Joint demonstration of ISFP SAFE-STADIUM, H2020 HoloZcan & H2020 NEST projects",
        person: "Marek Łepkowski",
        date: "01.06.2023",
        description: `<iframe width="100%" style=" height: 50vh; "src="https://www.youtube.com/embed/uXMZoUGtT9E" title="Bezpieczny stadion" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
        img: [],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02zsQSbsMwGZf3YrJHYujBfL4FHgy5kmtSorerZi3d9Fs1UTkmwgmFcaoYbHRJTHcnl"
    },

    {
        id: 14,
        title: "News - MSPO 2023 Kielce.",
        person: "Agata Antoniak",
        date: "08.09.2023",
        description: `We were present during the 31st International Defence Industry Exhibition in KIELCE – MSPO 2023. We had a fruitful discussions about our NEST project with military representatives, technical universities, etc. We are proud to inform that there was an impressive interest of the project results.</a>`,
        img: [{link: require("../../assets/img/MSPO2023a.jpg")},],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02VkR8hyneFLHP5niu5FjrZHwxfPgzmpQFEDb2SVNa8cyNPGsiyi4iimfvNfCzkfjZl"
    },

    {
        id: 15,
        title: "News - Fire Service exercises at Lech Poznań.",
        person: "Marek Łepkowski",
        date: "10.10.2023",
        description: `On Friday 29.09.2023 Poznań City Headquarters of the Fire Service conducted at Lech Poznań Stadium exercises focusing on CBRNE threats.
        <br> <br>
        The first part of the event focused  on theoretical aspects: types and summary of CBRN incidents, real-case scenarios, critical infrastructure safety and protection as well as management of (CBRN) threats at mass events. During the morning session also Safe Stadium, NEST and HoloZcan projects were presented by University of Lodz.
        <br> <br>
        The second part of the event focused on exercises consisting of three scenarios:
        <br> <br>
        – spraying chemical agents form a drone on fans of one of the stadium sectors,<br>
        – hanging of unidentified and suspicious object of the stadium roof structure,<br>
        – planting a suspicious object under the seats at one of the sectors.`,
        img: [{link: require("../../assets/img/lp1.jpeg")},{link: require("../../assets/img/lp2.jpeg")},{link: require("../../assets/img/lp3.jpeg")},{link: require("../../assets/img/lp4.jpeg")},{link: require("../../assets/img/lp5.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid01S2RtibHEdiSK6dtJ76YUWrBrSRnDj1S55AfLJjsoQJUt7NGSHvvAuQq9y2Q9yFpl"
    },

    {
        id: 16,
        title: "Implementing project NEST",
        person: "Marek Łepkowski",
        date: "27.12.2023",
        description: `On behalf of the consortium implementing project NEST, we have the honour to invite interested representatives of institutions to the demonstration of a prototype of an interoperable multidomain CBRN system (short name: NEST) supporting crisis management in dealing with the accidental release of biological, chemical or radiological hazardous materials. The live demonstration based on simulated action scenarios will be held at Arenas Atiram Hotel in Barcelona on January 23rd 2024, at 10:00 AM and is planned for 4 hours. Since the Consortium pays utmost attention to the participants' opinions on the demonstrated System and the method of demonstration, an additional feedback session will take place at the same venue on January 24th 2024, from 10 AM to 1 PM. 
        The NEST system envelops the detection of hazards covering large areas or structures and data transmission of hazard detectors’ sensor signals using architecture enabling long-range data transmission to the platform operating in the Internet of Things (IoT). The data managed by IoT are further used to feed Common Operational Picture (COP) and are visualised through augmented reality (AR). 
        The NEST project belongs to HORIZON Europe, the EU’s key funding programme for research and innovation. The NEST carrying consortium, whose competencies cover research and development, standardization and end-use assessment, consists of partners from Spain, Germany, Poland, and Portugal.
        The Arenas Atiram Hotel has prepared for us a special offer 
        75€ Double for a single use per night; 
        Breakfast, VAT included; City tax, not included, 
        This offer is valid till January 10th
        The agenda enclosed.
        Contact person:
        narcis.avellana@sensingcontrol.com
        alberto.fernandez@sensingcontrol.com
        manuel.lozano@csic.es`,
        img: [{link: require("../../assets/img/Pilot.jpg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02Sfp1y5HpDS7zUQwv8f5EW9BepYVXPzU1TQbBnvDPfVjTYkT9aQNVoHyhAnHK8Buyl"
    },

    {
        id: 17,
        title: "System demonstration exercise meeting",
        person: "Marek Łepkowski",
        date: "25.01.2024",
        description: `NEST System demonstration exercise meeting
        <br><br>
            The NEST technical meeting in Barcelona<br>
            On January 22-24, 2024, a technical meeting of the NEST project was held in Barcelona.<br>
            During the meeting presented NEST System demonstration exercise<br>
            System’s presentation objectives <br>
            The objectives of the demonstration and validation of the system are:<br><br>
            • Presentation the system components, demonstrate each of the functionalities individually, and position them against declared ambitions and progress.<br>
            • Presentation the system and explain its functional value, contribution to preparedness, consequences management, requirements and limitations.<br>
            • Installation the system in the dedicated environment and integrate it provisionally but maintain conformity to the working environment and regulatory requirements of the end-user.<br>
            • Running the technical test proving that all system elements are working.<br>
            • Running the scenario-based exercise based on simulated emergency situations, display and explain the System’s features and prove its integrity with the host end-user routine procedures.<br>`,
        img: [{link: require("../../assets/img/250120241.jpeg")},{link: require("../../assets/img/250120242.jpeg")},{link: require("../../assets/img/250120243.jpeg")},{link: require("../../assets/img/250120244.jpeg")},{link: require("../../assets/img/250120245.jpeg")},{link: require("../../assets/img/250120246.jpeg")},{link: require("../../assets/img/250120247.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02QDkBULgrJwh5CL86qmSkLMnmEZuUxGTmF3yL74ckbTx3Ukt6VCTkLKPFtw78Cr6ol"
    },
    {
        id: 18,
        title: "NEST System Pilot for Railway reference scenario (The NEST technical meeting in Lisbon)",
        person: "Marek Łepkowski",
        date: "20.02.2024",
        description: `Project consortia has an open meeting on 22-23.02.2024 in Lisbon for one of the three
        relevant scenarios: <br> <br>
        Public Transportation. <br> <br>
        The event will be held on premises of Comboios de Portugal where we will showcase the use
        NEST system under CBRN attack in a long-distance railcar. <br> <br>
        During the meeting presented NEST System demonstration exercise
        System’s presentation objectives
        The objectives of the demonstration and validation of the system are:
        • Presentation the system components, demonstrate each of the functionalities
        individually, and position them against declared ambitions and progress. <br> <br>
        • Presentation the system and explain its functional value, contribution to preparedness,
        consequences management, requirements and limitations. <br> <br>
        • Installation the system in the dedicated environment and integrate it provisionally but
        maintain conformity to the working environment and regulatory requirements of the
        end-user. <br> <br>
        • Running the technical test proving that all system elements are working. <br> <br>
        • Running the scenario-based exercise based on simulated emergency situations, display
        and explain the System’s features and prove its integrity with the host end-user routine
        procedures. <br> <br>
        Roles and responsibilities <br><br>
        
        
    <table>
    <tr>
        <th>Activity</th>
        <th>Participants</th>
    </tr>
    <tr>
        <td>Equipment configuration and making the System Operational</td>
        <td>Host technical assistant/maintenance as required Host, not technical, acting as a guide, advisor</td>
    </tr>
    <tr>
        <td>Trial operation of NEST system components with the stress on successful visualization</td>
        <td>NEST partners</td>
    </tr>
    <tr>
        <td>NEST demonstration objectives</td>
        <td>Demonstration details, simplifications</td>
    </tr>
    <tr>
        <td>Scenario description</td>
        <td>ULO</td>
    </tr>
    <tr>
        <td>Presentation of the demonstrated NEST components</td>
        <td>10 min. per each presentation - WoePal, CSIC, ULO, S&amp;C, GTSPT, DSC</td>
    </tr>
    <tr>
        <td>Table-top demonstration of the NEST system with activation of the sensors</td>
        <td>S&amp;C, ULO, WoePal, CSIC, GTSPT</td>
    </tr>
    <tr>
        <td>Demonstration</td>
        <td>All NEST partners</td>
    </tr>
    <tr>
        <td>Exercise evaluation</td>
        <td>S&amp;C All</td>
    </tr>
    <tr>
        <td>NEST functionalities discussion</td>
        <td>All</td>
    </tr>
</table>
        
        `,
        img: [],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02uyiXYXuaeS4PFk2BmKESTUsS72WyGEjhMs89MoDEHTSdt9e7f69uY8gV7YdV8WeBl"
    },

    {
        id: 19,
        title: "NEST community building  with Safe Stadium project- participation in CBRN event in Lodz (Poland) - 6-7 of March 2024",
        person: "Marek Łepkowski",
        date: "25.03.2024",
        description: `<a href="https://www.youtube.com/watch?v=mk2i_BXHqbc">https://www.youtube.com/watch?v=mk2i_BXHqbc</a> `,
        img: [{link: require("../../assets/img/106032024.jpeg")},{link: require("../../assets/img/206032024.jpeg")},{link: require("../../assets/img/306032024.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid0Y8F7LGMcZehA1peyQ7eKqFxHvZqVSvJK4HnVU1Fvm9fdFhRzGSeqG5yCo6P5kjKQl"
    },

    {
        id: 20,
        title: "NEST community building  with Safe Stadium project- participation in CBRN event in Lodz (Poland) - 6-7 of March 2024",
        person: "Marek Łepkowski",
        date: "25.03.2024",
        description: `<a href="https://www.youtube.com/watch?v=mk2i_BXHqbc">https://www.youtube.com/watch?v=mk2i_BXHqbc</a> `,
        img: [{link: require("../../assets/img/106032024.jpeg")},{link: require("../../assets/img/206032024.jpeg")},{link: require("../../assets/img/306032024.jpeg")}],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid0Y8F7LGMcZehA1peyQ7eKqFxHvZqVSvJK4HnVU1Fvm9fdFhRzGSeqG5yCo6P5kjKQl"
    },

    {
        id: 21,
        title: `NEST System Pilot for Railway reference scenario
        Comboios de Portugal, Lisbon, 22-23 of February 2024`,
        person: "Marek Łepkowski",
        date: "25.03.2024",
        description: `During the meeting presented NEST System demonstration exercise
        System’s presentation objectives 
        The objectives of the demonstration and validation of the system are:<br> <br>
        • Presentation the system components, demonstrate each of the functionalities individually, and position them against declared ambitions and progress.<br> <br>
        •Presentation the system and explain its functional value, contribution to preparedness, consequences management, requirements and limitations.<br> <br>
        •Installation the system in the dedicated environment and integrate it provisionally but maintain conformity to the working environment and regulatory requirements of the end-user.<br> <br>
        •Running the technical test proving that all system elements are working.<br> <br>
        •Running the scenario-based exercise based on simulated emergency situations, display and explain the System’s features and prove its integrity with the host end-user routine procedures.` ,
        img: [{link: require("../../assets/img/2125042024.jpeg")},{link: require("../../assets/img/2825042024.jpeg")},{link: require("../../assets/img/2325042024.jpeg")},{link: require("../../assets/img/2725042024.jpeg")},{link: require("../../assets/img/2525042024.jpeg")},{link: require("../../assets/img/2325042024.jpeg")},{link: require("../../assets/img/2625042024.jpeg")},{link: require("../../assets/img/2425042024.jpeg")},{link: require("../../assets/img/2225042024.jpeg")} ],
        fbLink: "https://www.facebook.com/NestEU2022/posts/pfbid02m5pvKoYSAsyeat2spJw3L4ywjPFMVHNv3GVuLigfMyVBRr1Ncer8qEuXuqSYvDfpl"
    },
    {
        id: 22,
        title: `NEST an European strategical security R&D project to protect against CBRN attacks`,
        person: "Marek Łepkowski",
        date: "25.03.2024",
        description: `NEST System Demonstration Poznań Stadium by LECH Poznań and NEST Exploitation Workshop 19-21 of March 2024 Poznań (Poland)`,
        img: [],
        fbLink: "https://nest-h2020.eu/DOC/Press_report_15_(03.2024)_news2.pdf"
    },

]

const backendNewsReverse = backendNews.reverse()
const newsList =  backendNewsReverse.map(el => (

    <Topicality
        key={el.id}
        {...el}/>
))



function News()
{
return(
    newsList
)
}

export default News