import { useEffect } from "react"
import "./fbContent.css"
function FbContent ()
{

    useEffect(() => {
        if (window.FB) {
            window.FB.XFBML.parse();
        }
    },[]);

    return(
        <div className="fb-baner d-none d-xl-block" >
 
        <h5>Social Media</h5>
        <div id="fb-root"></div>
            <div className="fb-page col-sm-12" 
            data-tabs="timeline,events,messages"
            data-href="https://www.facebook.com/NestEU2022"
            data-width= "300px"
            data-hide-cover="false"></div>
        </div>

    )
}



export default FbContent