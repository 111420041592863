import { useState } from "react"
import { useParams } from "react-router-dom"
import imgWorkPackages from "../../assets/img/workPackages.png"
import "./workPackages.css"


function workPackages()
{

    return(
       <>
       
            <div class="card mt-5 col-lg-8 offset-lg-2 mb-5 ">
            <img src={imgWorkPackages} class="card-img-top" alt="..."></img>

            </div>
       </>
    )
}

export default workPackages