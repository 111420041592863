import { useState } from "react"
import { useParams } from "react-router-dom"
import Partner from "../../components/partner/partner"
import LogoAtrimhotels from "../../assets/partnersLogo/logoAtiram.png"
import LogoSCG from "../../assets/partnersLogo/logoSCG.png"
import LogoCDP from "../../assets/partnersLogo/logoCDP.png"
import LogoDSC from "../../assets/partnersLogo/logoDSC.png"
import LogoCNM from "../../assets/partnersLogo/logoCNM.png"
import LogoLP from "../../assets/partnersLogo/logoLP.png"
import logoThales from  "../../assets/partnersLogo/logoThales.png"
import logoUNE from  "../../assets/partnersLogo/logoUNE.png"
import logoUL from  "../../assets/partnersLogo/logoUL.png"
import logoWOEPAL from  "../../assets/partnersLogo/logoWOEPAL.png"


function Partners()
{
    const beckendPartners = {
      Atiramhotels:
        {
            id: 0,
            title: "Atiramhotels",
            description: `A new hotel group that cares for the people, since 2014. At Atiram we do much more than just sell rooms. We like to establish a personal link with our customers, to break the ice between our staff and our valued guests. The hotels are located in Barcelona (4), Valencia (1), Andorra (4) and Manama (3). The Arenas Atiram Hotel is a 4 star hotel which offers 60 fully equipped rooms.

            The hotel is located in the main business area from Barcelona and has excellent access to public transport connections (metro, tram and buses) and only a few meters from the main Diagonal avenue. It is in the district where there are university campuses, the Camp Nou football stadium and several clinics.

            Main tasks in the project: As an end-user, ATI will be contribute to the end user requeriments in wp2and especially in the area of operational and functional requeriments. ATI will participate in the project trials as end-user and also in the analysis related to the evaluation of the project solutions. Communications activities and the dissemination of project results both at the country and EU levels.`,
            link: "https://www.atiramhotels.com",
            imgSrc: LogoAtrimhotels
        },
        Amper: {
            id: 1,
            
            title: "AMPER S&C IOT (S&C)",
            description: `AMPER S&C IOT (S&C) is a technology-based SME specialised in bringing to the market Internet of Things solutions for Security and Energy sectors. Enabled by its powerful web- based software platform, enControl, and combined with its extensive experience in wireless sensor & communication technologies, S&C is at the forefront of those entities entering this arena. In addition, S&C has acquired relevant experience in secure data flow, especially within applications that involve sensitive information such as security or healthcare records; encryption, password authentication and firewall/anti-virus techniques to prevent security breaches.

            Main tasks in the project: S&C will be the Project Coordinator responsible for internal management, financial, administrative tasks, quality control of the project and reporting. S&C will establish an efficient and professional project management and coordination (WP8) and continuously orientate all the activities with the assistance of all partners. S&C will provide ensure a smooth communication among partner and maintain a continuous contact with the EC representatives.
            
            S&C will lead the WP5 and all its tasks in order to create the software core of the NEST platform. That means the data acquisition, processing and storing modules and the connection between this back-end architecture and the front-end functionalities developed in WP4. Also, this WP is devoted to the overall integration and testing. Testing the NEST system in real environments will be done by participating in pilot tests.
            
            Finally, S&C will lead the T7.3 establish a strong exploitation strategy together with all partners and in addition will contribute to all tasks in WP7 making sure that all developments will be properly disseminated and reach the right communities for further business development and exploitation.`,
            link: "www.sensingcontrol.com",
            imgSrc: LogoSCG
            },

            CDP: {
              id: 2,
              
              title: "Comboios De Portugal",
              description: `CP – Comboios de Portugal, E.P.E. is the Portuguese state railway company, with over 3,700 workers. Our mission is to connect people and communities, in a sustainable way and based on the railway. Before the COVID 19 pandemic, our company transported annually the equivalent of 14 times the population of Portugal, with almost half a million trains circulating per year. Our workshops are spread across the national territory, with two main centers in Porto, one in the center of the country, three in Lisbon and two in the south. Since 2019 we have invested strongly in rolling stock recovery.

              Main tasks in the project:
              
              Our role at NEST is that of End User:
              
              WP1 – Analysis and requirements Production of scenarios, requirements, specifications; Input for the characterization of operational procedures;
              
              WP5 – Demonstration and training Collaboration in the demonstration and field tests; Evaluation of the training material; Final assessment of tests and best practices;
              
              WP6 - Dissemination and exploitation Cooperate in the dissemination and divulgation of the project;`,
              link: "www.cp.pt",
              imgSrc: LogoCDP
              },

              DSC: {
                id: 3,
                
                title: "Dynamic Safety Corporation",
                description: `Experience: 20 years on the Polish market, qualified and experienced personnel with over 40 finished projects for POLISH ARMED INSPECTORATE, POLICE, BORDER GUARDS, FIREFIGHTING BRIGADES, AIRPORTS, SPECIAL FORCES

                Specialization: Virtual Reality, Augmented Reality, Motion Capture, Passive Haptic, Head-Mounted Display
                
                Cooperation: the largest research and development centres and universities in Poland (eg. The National Center or Resarch and Development, Military University of Technology, Warsaw University of Technology, Silesian University of Technology, Police Academy in Szczytno)
                
                Experience: virtual training systems for law enforcement services, complete demonstration VR training modules, implementation of selected scenarios using VR, Motion Capture, AR
                
                Main tasks in the project:
                
                Developing and implementation of VR and AR solutions and Haptics technologies for the visualizations and to facilitate the rapid exchange of information on the current threat situation.
                
                WP 7 leader (Dissemination, exploitation and communication) Lead Partner:
                
                WP 4: Augmented Reality (AR) framework development & integration Coherent AR Design Elements for HMI Operational staff application development with AR elements
                
                WP 5: Overall testing and test reports`,
                link: "www.dsc-vr.pl",
                imgSrc: LogoDSC
                },

                IMB: {
                  id: 4,
                  
                  title: "IMB-CNM",
                  description: `The Barcelona Institute of Microelectronics (IMB-CNM), part of the Spanish National Research Council (CSIC), is a public research centre specialised in the development of new Micro/Nano Integrated Systems: miniaturized electronic systems which include sensing and/or actuating capabilities in addition to electronic information processing, power management and external interfaces. With 188 employees (35% female), IMB-CNM produces on average 100 peer-reviewed scientific articles and about 10 patents per year; 50% for its operational budget comes form competitive research grants.`,
                  link: "www.imb-cnm.csic.es",
                  imgSrc: LogoCNM
                  },

                LP: {
                  id: 5,
                  
                  title: "Lech Poznań",
                  description: `Lech Poznan - the leading football club in Poland, plays its matches on a modern stadium built in 2010. Every year it organizes over 20 mass events with an average attendance of approx. 20000 people. The stadium hosted matches of the EURO 2012 tournament, and the club and its staff were responsible for the security. Organizer of cultural and music events with an audience of approx. 30000 people. It regularly appears in European competitions

                  Main tasks in the project: End-User - testing solutions prepared by Partners LP will contribute to the end user requirements and especially in the area of operational and functional requirements. LP will participate in the project trials as end-user and also in the analysis related to the evaluation of the project solutions. He is also the main contact point responsible for the communications activities and the dissemination of project results both at the country and EU levels.`,
                  link: "www.lechpoznan.pl",
                  imgSrc: LogoLP
                  },

                  Thales: {
                    id: 6,
                    
                    title: "Thales",
                    description: `Thales Portugal is a Subsidiary of the Thales Group, with the mission to integrate and develop solutions and products for Thales projects worldwide. Thales Portugal has a strong capability to design, develop, and deploy systems and services efficiently in the Defense, Security (Critical Information Systems, Critical Infrastructure and Banking) and Transportation markets. Recognized as key technology partner, Thales Portugal is leader in the transport segment, with a strong presence in worldwide Rail Communication and Signaling projects, leading also the developing products for Passenger Information and Security systems. As part of its technical strategy Thales Portugal participates in various research projects and is particularly active in topics related to security and information management in public transportation environments. Under the R&D activities a special focus has been given to human interface, interaction and communication with improved cognition and consistency evaluation of the information exchanged and information fusion, analytics and presentation for general operational purposes and for decision support.

                    Main tasks in the project: Thales Portugal Thales main roles will be associated with the development of integration, visualization and human interaction components leading WP4, and assuming in particular the development of the NEST user interface and underlying model for decision support. At the level of the platform, Thales will also be in charge of the end-to-end security framework. Thales will also participate and provide support to other work packages being specially relevant, architectural and interfaces definition and overall integration activities.`,
                    link: "www.thalesgroup.com",
                    imgSrc: logoThales
                    },

                    UNE: {
                      id: 7,
                      
                      title: "UNE",
                      description: `The Spanish Association for Standardization, UNE, is legally designated as the National Standardization Body of Spain since 1987. It is the national representative and member on the European (CEN and CENELEC), International (ISO and IEC) and Pan-American (COPANT) Standards Organizations, and member of the European Telecommunications Standards Institute (ETSI). As a part of the standardization activities, UNE is very active in integrating standardization in R&I projects, with experience in more than 80 funded European and national projects and also provides support, information and training to Europe-wide R&I actors.

                      Main tasks in the project: UNE supports NEST in all aspects related to standardization, from the start to the end of the project and beyond. Especially, by identifying existing standards, engaging with standardization organizations, identifying standardization potential of project results and promoting the growth of new standards covering NEST’s outcomes. The aim is contributing to increase the impact of the project, especially among industry, society and public authorities.`,
                      link: "www.une.org",
                      imgSrc: logoUNE
                      },

                      UL: {
                        id: 8,
                        
                        title: "Uniwersytet Łódzki",
                        description: `Established in 1945 University of Lodz is one of the biggest public universities in Poland (12 faculties; over 2 200 Academic staff; over 28 000 students). In project mainly are involved two Faculties: Faculty of Biology and Environmental Protection and Faculty of Chemistry. Biohazard Prevention Centre is a scientific unit dealing with issues related to biosafety, biosecurity, CBRN threats, public places protection, biosensors detection etc. The research work in the Department of Organic and Applied Chemistry is focused on synthetic organic chemistry and related fields such as bioorganic and heterorganic chemistry.`,
                        link: "www.uni.lodz.pl",
                        imgSrc: logoUL
                        },

                        WOEPAL: {
                          id: 9,
                          
                          title: "WoePal GmbH",
                          description: `WoePal GmbH is a small, medium-sized company offering innovative in-situ sensor solutions for a variety of trace gases. The company’s focus is on the development of sensors and complete sensor systems, which are specifically tailored to customer requirements. Combining micro-system with optical technologies, WoePal GmbH offers cost-effective, innovative solutions for the selective and sensitive detection of chemical compounds, air pollutants and greenhouse gases. In combination with network solutions such as Bluetooth, ZigBee and LoRa, WoePal GmbH develops technology solutions that enable IoT sensor applications and extended networks, both for scientific and industrial applications.

                          The WoePal GmbH is supporting the NEST project with its longstanding experience in IoT and sensor technologies by leading work package 3, which is designed for developing sensors and common base unit. Its main contribution will be the development of a chemical sensing technology, which includes thorough testing and bench-marking of the developed sensing technologies. Furthermore, WoePal GmbH is responsible for the design and management of a common interface for all sensing technologies of NEST.
                          
                          Contributions to other work packages include support for state of the art reviews and system design and specifications; COP logic and support layer tool, platform development and layers integration; technical Implementation – IoT Detector Platform, integrated test cases, overall integration, overall testing and test reports`,
                          link: "www.woepal.de",
                          imgSrc: logoWOEPAL
                          },
          
        
    }


  //  const [idPartne, setidPartne] = useState({})
    const params = useParams()
    const content = beckendPartners[params.id]
    const test = params.id
  //  setidPartne(params)
    console.log(content)
    return(
<Partner key={content.id}
        {...content}/> 
    )
}

export default Partners