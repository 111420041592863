import BreakLine from '../../components/breakLine/breakLine'
import "./topicality.css"
import Carousel from 'react-bootstrap/Carousel';
import logo from '../img/Ellipse_7.png';

function imgNewsGenerate(img)
{

    let listImg = img.map((imgNews, index) => 
    <div key={index} class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
        {console.log(imgNews.id)}
        <a href={imgNews.link} target="_blank">
        <img
            className="d-block w-100 boxNews__Img "
            src= {imgNews.link}
            alt="First slide"
            />
            </a>
    </div>)
return listImg;
}

function linkGenerator(link)
{
    console.log(link)
    if(link.length > 0)
    {
        return(
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-12 text-end">
        
            <a href={link} target="blank">Read more...</a> 
       </div> 
        )
    }
    return ""
}

function Topicality(props)
{
    const fbLink = props.fbLink;
return(
<>

    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4 ">

    <div class="row justify-content-center">



<div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 boxNews p-2 px-4 ">
    
    <div class="row">
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 boxNews__InformationTitle mb-3 ">
            
            <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">Posted by: {props.person} </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-end">Date: {props.date}</div>
            
            </div>

        </div>
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <h4>    <img src={logo} className="news__Logo" alt="logo"  />{props.title}</h4> 
        </div>
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" dangerouslySetInnerHTML={{ __html: props.description }}>
         
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4 mb-4">
    
    <div class="row">
        
    {imgNewsGenerate(props.img) 
    } 
    {
   linkGenerator(props.fbLink)
    }

    
    </div>


        
    </div>
    
</div>

        

        
        
    </div>

    </div>
    </div>

    </>
    
)
}

export default Topicality