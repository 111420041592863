import { useState } from "react"
import { useParams } from "react-router-dom"
import Contact from "../../components/contact/contact"


function Contacts()
{

    return(
        <Contact></Contact>
    )
}

export default Contacts