import "./header.css"
import nestHeader from "../img/nest_Header.png"
import iconFb from "../img/Fb_Icon.jpg"
import iconTwitter from "../img/Tw_Icon.jpg"
import iconLinkedin from "../img/ld_Icon.jpg"
import BreakLine from '../breakLine/breakLine';
function Header ()
{
return(

<div className="header-white col-xs-12 col-sm-12 col-md-12 col-lg-12">
    
    <div class="container-fluid">
        

    
    <div className="row">
        
        <div className="col-xs-7 col-sm-7 col-md-7 col-lg-7 offset-white">
            
            <div className="row row-header">
                
                <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-nest-header d-none d-sm-block">
                    
                    <img src={nestHeader} className="img-fluid" alt="Image" />
                    
                </div>

                
                <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 mobile-padding-header">
                <div className="row">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-nest-header">
                        <h5>Information:</h5>
                    </div>
                    
                    <div className="col-5 col-sm-6 col-md-6 col-lg-6">
                        
                        <div className="row">
                            
                            <div class="col-xs-2 col-sm-2 col-md-2 col-lg-7 offset-lg-7 col-nest-header">
                                <a href="https://www.facebook.com/NestEU2022"> <img src={iconFb} className="img-fluid icon-img" alt="Icon FB" /></a>
                                <a href="https://twitter.com/NestEU2022"><img src={iconTwitter} className="img-fluid icon-img" alt="Icon Twitter" /></a> 
                                <a href="https://www.linkedin.com/groups/12552102/"><img src={iconLinkedin} className="img-fluid icon-img" alt="Icon Likedin" /></a>
                            </div>
                            
                        </div>
                        

                    </div>
                </div>
                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 margin-text-header">
                    <p>Programme: Horizon 2020 Framework Programme Secure societies - Protecting freedom and security of Europe and its citizens. Topic: SU-DRS04-2019-2020 “Chemical, biological, radiological and nuclear (CBRN) cluster”. Acronym: NEST. Grant agreement number: 101018596 – NEST. Duration: May 2021 - May 2024.</p>
                </div>
                
                </div>
                <div className="d-block d-sm-none">
                <BreakLine ></BreakLine>
                </div>




                
            </div>
            
        </div>
        


    </div>
    </div>
</div>
)
}

export default Header