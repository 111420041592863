import BreakLine from '../../components/breakLine/breakLine'
function Video(props)
{
return(
<>
<BreakLine />
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mt-4">

    <div class="row">



        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-7">
        <h3>{props.name}</h3>
            <p className='mt-2'>{props.description}</p>
        </div>

        <div class="col-xs-7 col-sm-7 col-md-7 col-lg-4">

            <video width="100%" height="auto" controls>
                <source src={props.src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
        

        
        
    </div>







    </div>

    </>
    
)
}

export default Video