import "./partner.css"

function Partner(props)
{
    return(
        
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div className="row">
                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <h2>{props.title}</h2>
                    <a href={props.link}>{props.link}</a> 
                </div>
                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <img src={props.imgSrc} alt="Logo" />
                </div>

                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4 partner-description">
                    <p>{props.description}</p>
                </div>
                
                
            </div>
            
        </div>
        
    )
}

export default Partner