import img1 from "../img/informationImg/1.png"
import Information from "./information/information"

function SectionInformations()
{
    const information = [
        {
          id: 0,
          title: "About",
          text: `Programme: Horizon 2020 Framework Programme Secure societies - Protecting freedom
          and security of Europe and its citizens.
          Topic: SU-DRS04-2019-2020 “Chemical, biological, radiological and nuclear (CBRN)
          cluster”
          Grant agreement number: 101018596 – NEST
          Duration: May 2021 - May 2024
          Acronym: NEST`,
          img: img1
        }
      ]
      
      const informationList = information.map(el => (
        <Information 
          key={el.id}
          {...el} />
      ));

      return(
        informationList
      )
}

export default SectionInformations