import logoUof from "../img/partners/uof.png"
import logoAti from "../img/partners/ati.png"
import logoScg from "../img/partners/scg.png"
import logoCde from "../img/partners/cde.png"
import logoDsc from "../img/partners/dsc.png"
import logoCnm from "../img/partners/cnm.png"
import logoKks from "../img/partners/kks.png"
import logoTha from "../img/partners/tha.png"
import logoUne from "../img/partners/une.png"
import logoWoe from "../img/partners/woe.png"

function Partners()
{
    return (
        
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            
            <div className="row">
                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                    <h4>Partners:</h4>
                </div>
                
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">

                    <img src={logoUof} className="img-fluid icon-img" alt="Image" />
                    <img src={logoAti} className="img-fluid icon-img" alt="Image" />
                    <img src={logoScg} className="img-fluid icon-img" alt="Image" />
                    <img src={logoCde} className="img-fluid icon-img" alt="Image" />
                    <img src={logoDsc} className="img-fluid icon-img" alt="Image" />
                    <img src={logoCnm} className="img-fluid icon-img" alt="Image" />
                    <img src={logoKks} className="img-fluid icon-img" alt="Image" />
                    <img src={logoTha} className="img-fluid icon-img" alt="Image" />
                    <img src={logoUne} className="img-fluid icon-img" alt="Image" />
                    <img src={logoWoe} className="img-fluid icon-img" alt="Image" />


                </div>
                
                
            </div>
            
        </div>
        
    )
}

export default Partners