import "./information.css"
import BreakLine from "../../breakLine/breakLine"

function SectionInformation(props)
{
    return(
        <div> 
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 section-information">
                
                <div className="row">
                    
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                        <h4>{props.title}</h4>
                        <p>{props.text}</p>
                    </div>
                    
                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                    <img src={props.img} className="img-fluid icon-img" alt="Image" />
                    </div>
                    
                </div>
                
            </div>
        <BreakLine />
        </div>
    )
}

export default SectionInformation