import { useState } from "react"
import { useParams } from "react-router-dom"
import Contact from "../../components/contact/contact"


function Documents()
{

    return(
        
        <div class="row">
            
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 offset-lg-2 ">
                <ul class="list-group">
                    <li class="list-group-item">Press Release No 1_04.2022 <a href="https://nest-h2020.eu/DOC/Press_relase_1.pdf">Link</a> </li>
                    <li class="list-group-item">Press Release No 2_07.2022 <a href="https://nest-h2020.eu/DOC/Press_relase_2.pdf">Link</a>  </li>
                    <li class="list-group-item">Press Report 3 <a href="https://nest-h2020.eu/DOC/Press_report_3.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 4 <a href="https://nest-h2020.eu/DOC/Press_report_4.pdf">Link</a>  </li>
                    <li class="list-group-item">Press Report 5 <a href="https://nest-h2020.eu/DOC/Press_report_5.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 6 <a href="https://nest-h2020.eu/DOC/Press_report_6.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 7 <a href="https://nest-h2020.eu/DOC/Press_report_7.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 8 <a href="https://nest-h2020.eu/DOC/Press_report_8.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 9 <a href="https://nest-h2020.eu/DOC/Press_report_9.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 10 <a href="https://nest-h2020.eu/DOC/Press_report_10.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 11 <a href="https://nest-h2020.eu/DOC/Press_report_11.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 12 <a href="https://nest-h2020.eu/DOC/Press_report_12.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 13 <a href="https://nest-h2020.eu/DOC/Press_report_13.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 14 <a href="https://nest-h2020.eu/DOC/Press_report_14.pdf">Link</a> </li>
                    <li class="list-group-item">Press Report 15 <a href="https://nest-h2020.eu/DOC/Press_report_15.pdf">Link</a> </li>
                  </ul>
            </div>
            
        </div>
        

    )
}

export default Documents