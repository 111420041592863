import "./navbar.css"
import "lato-font"
import logo from '../img/IconNav.png';
import logoMobile from "../img/nest_Header.png"
import {Link} from "react-router-dom";
import { Outlet } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/js/bootstrap.bundle';


function Navbar({children}){

    return(
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
     <Link 

    to={`/`}
    className={`navbar-brand`}>
    <img src={logo} className="Nav-logo d-none d-sm-block" alt="logo"  />
    <img src={logoMobile} className="Nav-logo d-block d-sm-none" alt="logo"  />
    
  </Link>


  
  <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav mr-auto">


      <li className="nav-item">
      <Link 
          to={`/About`}
          className={`nav-link`}>
            
          About
        </Link>
      </li>

      <Dropdown>
      <Dropdown.Toggle className="nav-link" variant=" btn-deep-purple" id="dropdown-basic">
      Partners
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="/Partners/Atiramhotels">Atiramhotels</Dropdown.Item>
        <Dropdown.Item href="/Partners/Amper">AMPER S&C IOT (S&C)</Dropdown.Item>
        <Dropdown.Item href="/Partners/CDP">Comboios De Portugal</Dropdown.Item>
        <Dropdown.Item href="/Partners/DSC">Dynamic Safety Corporation</Dropdown.Item>
        <Dropdown.Item href="/Partners/IMB">IMB-CNM</Dropdown.Item>
        <Dropdown.Item href="/Partners/LP">Lech Poznań</Dropdown.Item>
        <Dropdown.Item href="/Partners/Thales">Thales</Dropdown.Item>
        <Dropdown.Item href="/Partners/UNE">UNE</Dropdown.Item>
        <Dropdown.Item href="/Partners/UL">Uniwersytet Łódzki</Dropdown.Item>
        <Dropdown.Item href="/Partners/WOEPAL">WoePal GmbH</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown>

      <li className="nav-item">
      <Link 
          to={`/News`}
          className={`nav-link`}>
          News
        </Link>
      </li>

      <li className="nav-item">
      <Link 
          to={`/Documents`}
          className={`nav-link`}>
          Documents
        </Link>
      </li>

      <li className="nav-item">
      <Link 
          to={`/Publication`}
          className={`nav-link`}>
          Publication
        </Link>
      </li>

      <li className="nav-item">
        <Link 
          to={`/Video`}
          className={`nav-link`}>
          Video
        </Link>

      </li>

      <li className="nav-item">
      <Link 
          to={`/WorkPackages`}
          className={`nav-link`}>
            
          Work Packages
        </Link>
      </li>

      <li className="nav-item">
      <Link 
          to={`/Contact`}
          className={`nav-link`}>
          Contact
        </Link>
      </li>

    </ul>
  </div>

</nav>
{children}
</div>
    )
}

export default Navbar;
<li className="nav-item dropdown"><a href="#" id="navbardrop" data-toggle="dropdown" className="nav-link dropdown-toggle">
                Partners
            </a> <div className="dropdown-menu"><a href="atiramhotels.html" className="dropdown-item">Atiramhotels</a> <a href="amper.html" className="dropdown-item">AMPER S&amp;C IOT (S&amp;C)</a> <a href="cdp.html" className="dropdown-item">Comboios De Portugal</a> <a href="dsc.html" className="dropdown-item">Dynamic Safety Corporation</a> <a href="imbcnm.html" className="dropdown-item">IMB-CNM</a> <a href="lechpoznan.html" className="dropdown-item">Lech Poznań</a> <a href="thales.html" className="dropdown-item">Thales</a> <a href="une.html" className="dropdown-item">UNE</a> <a href="uniLodz.html" className="dropdown-item">Uniwersytet Łódzki</a> <a href="wopal.html" className="dropdown-item">Woepal</a></div></li>