import { useState } from "react"
import { useParams } from "react-router-dom"
import Contact from "../../components/contact/contact"
import BreakLine from "../../components/breakLine/breakLine"
import "./about.css"


function About()
{
    return(
        <>
        <BreakLine></BreakLine>
        <br/><br/>
        <p> <span className="fontText">NEST</span>  will design and implement a novel and unique standoff system with the capability to detect multiple threats amongst which are CBRN threats or pandemic viruses. As the day-to-day protection of commercial and transport facilities is the responsibility of the owners and operators, in close cooperation with local law enforcement, <span className="fontText">NEST</span>  will support owners, operators, and security staff by providing (i) threat indications and warnings, and (ii) guidance for facility security by developing appropriate information-sharing and analysis mechanisms.
        <br/><br/>
          The system will rely on the simultaneous use of low-cost CBRN detectors embedded in one unique detection equipment, which can be located into different sites inside the building or carried by security staff. The use of low-cost sensors will enable to cover a wide space inside. <span className="fontText">NEST</span>  will help in the early detection of CBRN threats in real time, and also provide complementary information — such as such location of threats, temperature, humidity, time, operators involved, etc. —
          useful for auditing or investigation purposes. These functionalities will be achieved by using an IoT platform capable of acquiring, processing, and merging data from internal and third-party services. Artificial intelligence will be applied to support decision process for securing facilities and for generating automatic alerts.
          <br/><br/>
          Furthermore, augmented reality will be used to display threats and hazards in a manner that minimise distraction and cognitive failures.
          <span className="fontText"> NEST</span>  will be validated in three different scenarios within the transport and commercial sectors. These scenarios include a diverse range of sites that draw large crowds of people. <span className="fontText">NEST</span>  will share information with the command centers of a stadium, a transport system, and a hotel to assess the risk situation. As a result of this action, owners, operators, and security staff will benefit from a universal system that will lay the foundations for creating a standardisation framework.</p>
        </>
    )
}

export default About