import { useState } from "react"
import { useParams } from "react-router-dom"
import Contact from "../../components/contact/contact"
import "./publication.css"


function Publication()
{

    return(
        
        <div class="row">
            
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 offset-lg-2 content">
                <ul class="list-group">
                    <li class="list-group-item">Immunosensors—The Future of Pathogen Real-Time Detection <a href="https://nest-h2020.eu/DOC/sensors-22-09757.pdf">Link</a> </li>
                  </ul>
            </div>
            
        </div>


    )
}

export default Publication