import "./breakLine.css"

function BreakLine()
{
    return (
        
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 breakLine">
            
        </div>
        
    )
}

export default BreakLine