import movie1 from '../../assets/videos/nest.mp4'
import movie2 from '../../assets/videos/NestDemo.mp4'
import Video from '../../components/video/video'


const backendVideos = [
    {
        id: 1,
        name: "An interoperable multidomain CBRN system",
        description: "Video showcases the scope of work and tells the story of the system. It highlights the key features and functions of the system, as well as its benefits and potential applications.",
        src: movie1
    
    },
    {
        id: 2,
        name: "Nest Demonstration movie",
        description: "",
        src: movie2
    
    }

]


const vieosList =  backendVideos.map(el => (

    <Video
        key={el.id}
        {...el}/>
))



function Videos()
{
return(
    vieosList
)
}

export default Videos