import logo from './components/img/Icon.svg';
import './App.css';
import React, { useState, useEffect, useRef} from 'react';
import ReactDOM from "react-dom";
import HeaderStart from './components/header/header';
import Partners from './components/partners/partners';
import BreakLine from './components/breakLine/breakLine';
import SectionInformations from './components/sectionInformations/sectionInformations';
import Navbar from './components/navbar/navbar'
import BackgroundWave from './components/backgroundWave/backgroundWave';
import Layout from './components/layout/layout'
import FbContent from './components/fbContent/fbContent';
import {BrowserRouter ,  Routes,  Route, Navigate } from "react-router-dom";
import Videos from './pages/video/Videos'
import PartnersSite from './pages/partners/partners';
import Contacts from './pages/contacts/contacts';
import News from './pages/news/news';
import Documents from './pages/documents/documents';
import About from './pages/about/about';
import WorkPackages from './pages/workPackages/workPackages';
import Footer from './components/footer/footer';
import Publication from './pages/publication/publication';


function App() {
  const header = (
      <div className="container-fluid container__index">
        <BackgroundWave 
          header = {<HeaderStart />}
        />
      </div>
  );

const content = (
  
  <>
  <Partners />

  
  <div class="row">
  <div class="col-xs-12 col-sm-10 col-md-10 col-lg-9">
  <BreakLine />
      <SectionInformations />
  </div>
  
  <div class="col-xs-2 col-sm-2 col-md-2 col-lg-3">
    <br />
    <FbContent />
  </div>
  </div>
  
<Footer></Footer>
  
</>
)

const main = (
  <BrowserRouter basename='' >
  <Routes>

  <Route exact={true} path="/" element={<><Navbar /><Layout
        header={header}
        content={content}
      /></>}>
  </Route>

  <Route path="*" element={<><Navbar /><Layout
        header={header}
        content={content}
      /></>}/>


  <Route path="/Video" element={<><Navbar /><Layout
        header={header}
        content={<><Videos></Videos><Footer></Footer></>}
      /></>}>
  </Route>

  <Route path="/Partners/:id" exact={true} element={<><Navbar /><Layout
        header={header}
        content={<PartnersSite />}
      /></>}>
  </Route>


  <Route path="/Contact" element={<><Navbar /><Layout
        header={header}
        content={<><Contacts /><Footer></Footer></>}
      /></>}>
  </Route>

  <Route path="/News" element={<><Navbar /><Layout
        header={header}
        content={<><News /><Footer></Footer></>}
      /></>}>
  </Route>

  <Route path="/Documents" element={<><Navbar /><Layout
        header={header}
        content={<><Documents /><Footer></Footer></>}
      /></>}>
  </Route>

  <Route path="/About" element={<><Navbar /><Layout
        header={header}
        content={<><About /><Footer></Footer></>}
      /></>}>
  </Route>

  <Route path="/WorkPackages" element={<><Navbar /><Layout
        header={header}
        content={<><WorkPackages /><Footer></Footer></>}
      /></>}>
  </Route>
  <Route path="/Publication" element={<><Navbar /><Layout
        header={header}
        content={<><Publication /><Footer></Footer></>}
      /></>}>
  </Route>

  <Route path="/linkedin" element={<Linkedin />} />
</Routes>
</ BrowserRouter>


)

  return ( 
   /* <div>
    <BrowserRouter>
    <Layout
      navbar = {<Navbar></Navbar>}
      header={header}
      content={home}
    />
</BrowserRouter>
</div>
*/
main


  );
}

function Linkedin() {
  async function redirect() {
    window.location.href = 'https://www.linkedin.com/in/nest-eu-ab8a51235/';
  }
  redirect();
  return <Navigate to="/" />;
}

export default App;
