import BreakLine from '../../components/breakLine/breakLine'
import eu from "../img/ueFlag.jpg"
import "./footer.css"
function Footer()
{
return(
<> 


    <div classNameName="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mt-4 mb-4 ">

    <div className="row">



        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-5 mt-4 footer">

                  
<div class="container">
                  <div class="row">
                    
                    <div class="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                        <p className="textFooter">This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 101018596. The content of this webpage represents the views of the author only and is his/her sole responsibility. The European Commission does not accept any responsibility for use that may be made of the information it contains.</p> 

                        
                    </div>
                    
                    <div class="col-xs-1 col-sm-1 col-md-2 col-lg-2">
                    <img src={eu} className="Nav-logo d-none d-sm-block" alt="logo"  />
                    </div>
                    
                  </div>
                  

        </div>


        

        
        
    </div>







    </div>
    </div>
    </>
    
)
}

export default Footer