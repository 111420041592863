import BreakLine from '../../components/breakLine/breakLine'
function Contact()
{
return(
<>
<BreakLine />

    <div classNameName="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center mt-4 mb-4">

    <div className="row">



        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-5 mt-4">

                  

                <h4>Project Coordinator</h4>
                  
                  <p> Dr Narcís Avellana</p>
                
                  <a href="mailto:narcis.avellana@sensingcontrol.com">narcis.avellana@sensingcontrol.com</a>
                  <br/><br/>
                  <h4> Technical Coordinator</h4>
                  
                  <p> Dr Manuel Lozano</p>
                 
                  <a href="mailto:manuel.lozano@csic.es">manuel.lozano@csic.es</a>

        </div>

        <div className="col-xs-7 col-sm-7 col-md-7 col-lg-4">


        </div>
        

        
        
    </div>







    </div>

    </>
    
)
}

export default Contact